import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <section>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <Link to="/">Takaisin sinne missä tapahtuu</Link>
  </section>
)

export default NotFoundPage
